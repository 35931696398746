<template>
    <div class="d-flex gap-1">
        <button v-for="field in fields" :class="btnClass" @click="() => setSortDirection(field)">
            <span>Sort by {{ field.name }}</span>
            <i v-if="field === activeField" class="bi ms-2" :class="getIconClass(field)"></i>
        </button>
    </div>
</template>

<script setup lang="ts">
import type DataObject from 'o365.modules.DataObject.ts';
import LocalStorageHelper from 'o365.modules.StorageHelpers.ts';
import { onMounted, ref } from 'vue';

type SortDirection = 'asc' | 'desc' | '';
type FieldRecord = Record<string, SortDirection>;
type Field = {
    name: string,
    sortDirection: SortDirection,
};

const props = withDefaults(defineProps<{
    dataObject: DataObject
    fields: string[]
    btnClass?: string
}>(), {
    btnClass: 'btn btn-sm btn-link'
});

const fields = ref<Field[]>([]);
const activeField = ref<Field | null>();

const storageKey = 'active-sort-field';
const initialSortOrder = props.dataObject.recordSource.getSortOrder();

const sortDirectionToIconClassMap = new Map([
    ['asc', 'bi-sort-up'],
    ['desc', 'bi-sort-down'],
]);

const sortDirectionMap = new Map<SortDirection, SortDirection>([
    ['', 'asc'],
    ['asc', 'desc'],
    ['desc', ''],
]);

const getIconClass = ({ sortDirection }: Field): string => {
    console.log(sortDirectionToIconClassMap.get(sortDirection));
    return sortDirectionToIconClassMap.get(sortDirection) ?? '';
}

const getStoredActiveField = (): FieldRecord | null => {
    try { return JSON.parse(LocalStorageHelper.getItem(storageKey)); }
    catch { return null; }
};

const setSortDirection = (field: Field) => {
    if (activeField.value && field !== activeField.value) {
        activeField.value.sortDirection = '';
    }

    field.sortDirection = sortDirectionMap.get(field.sortDirection) ?? '';

    if (field.sortDirection === '') {
        activeField.value = null;
        LocalStorageHelper.removeItem(storageKey);
        setSortOrder(initialSortOrder);

        return;
    }

    activeField.value = field;
    LocalStorageHelper.setItem(storageKey, JSON.stringify({ [field.name]: field.sortDirection }));
    setSortOrder([{ [field.name]: field.sortDirection }]);
};

const setSortOrder = (sortOrder: FieldRecord[]) => {
    props.dataObject.recordSource.setSortOrder(sortOrder);
    props.dataObject.load();
};

onMounted(() => {
    fields.value = props.fields
        .filter((name) => props.dataObject.fields.hasOwnProperty(name))
        .map((name) => ({ name, sortDirection: '' }));

    const storedFieldRecord = getStoredActiveField();

    if (!storedFieldRecord) return;

    const [storedFieldName, storedSortDirection] = Object.entries(storedFieldRecord)[0];

    const field = fields.value
        .find(field => field.name === storedFieldName);

    if (!field) return;

    field.sortDirection = storedSortDirection;
    activeField.value = field;
    
    setSortOrder([storedFieldRecord]);
});
</script>
